import 'commonsui/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom';

import {ApolloProvider} from '@apollo/react-hooks';
import {CookiesProvider} from 'react-cookie';
import {BrowserRouter} from 'react-router-dom';

import client from './client';
import App from './App';
import AuthProvider from './context/auth-context';

ReactDOM.render(
	<CookiesProvider>
		<ApolloProvider client={client}>
			<AuthProvider>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</AuthProvider>
		</ApolloProvider>
	</CookiesProvider>,
	document.getElementById('root')
);
