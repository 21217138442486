import React, {useMemo, useEffect} from 'react';
import {useLazyQuery} from '@apollo/react-hooks';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {Row, Col, Spinner} from 'react-bootstrap';

import {ENDPOINT} from '../../queries/status';
import ErrorLog from './ErrorLog';

import styles from './css/EndpointOverview.module.css';

export default function EndpointOverview(props) {
	const {id, fromDate, activeEndpointID} = props;
	const [fetchEndpoint, {data, loading, error}] = useLazyQuery(ENDPOINT, {variables: {id, fromDate}});

	useEffect(() => {
		if (activeEndpointID === id) {
			fetchEndpoint();
		}
	}, [activeEndpointID, fetchEndpoint, id]);

	const chartData = useMemo(() => {
		if (!data) return {};
		const responses = data.endpoint.Responses;

		return responses.map((er) => {
			return [
				new Date(er.CreatedAt).getTime(),
				!er.Error || (er.Error && !!er.Error.StatusCode) ? er.ResponseTime : null,
			];
		});
	}, [data]);

	if (data) {
		return (
			<div className="mt-4">
				<Row>
					<Col md={{offset: 2, span: 4}}>
						<h1 className="text-center mb-0 display-3">{data.endpoint.UptimePercentage}%</h1>
						<p className="text-secondary text-center">Uptime</p>
					</Col>
					<Col md={4}>
						<h1 className="text-center mb-0 display-3">{data.endpoint.AverageResponseTime} ms</h1>
						<p className="text-secondary text-center">Average Response</p>
					</Col>
				</Row>
				<HighchartsReact
					highcharts={Highcharts}
					options={{
						credits: false,
						title: false,
						chart: {
							zoomType: 'x',
						},
						xAxis: {
							type: 'datetime',
						},
						yAxis: {
							min: 0,
							gridLineColor: 'transparent',
							visible: false,
						},
						legend: {
							enabled: false,
						},
						time: {
							useUTC: false,
						},
						plotOptions: {
							area: {
								fillColor: {
									linearGradient: {
										x1: 0,
										y1: 0,
										x2: 0,
										y2: 1,
									},
									stops: [
										[0, Highcharts.getOptions().colors[1]],
										[1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
									],
								},
								marker: {
									enabled: false,
								},
								lineWidth: 1,
								states: {
									hover: {
										lineWidth: 1,
									},
								},
								threshold: null,
							},
						},
						series: [
							{
								type: 'area',
								name: 'ms',
								data: chartData,
							},
						],
					}}
				/>
				<ErrorLog endpointID={id} fromDate={fromDate} />
			</div>
		);
	} else if (loading) {
		return (
			<div className={`${styles.loadingContainer} mt-4 text-center`}>
				<Spinner animation="border" />
			</div>
		);
	} else if (error) {
		return <p>Error loading endpoint data.</p>;
	} else {
		return null;
	}
}
