import gql from 'graphql-tag';

export const LOGIN = gql`
	mutation Login($email: String!, $password: String!, $remember: Boolean!) {
		login(email: $email, password: $password, remember: $remember) {
			success
			userData {
				ID
				Name
				Email
				AvatarURL
				Entitlements {
					Roles
				}
			}
		}
	}
`;

export const LOGOUT = gql`
	mutation {
		logout
	}
`;

const moduleExports = {LOGIN, LOGOUT};

export default moduleExports;
