import gql from 'graphql-tag';

export const serviceFieldsFragment = gql`
	fragment ServiceFields on Service {
		ID
		Name
		Endpoints {
			ID
			Name
			Status
		}
	}
`;

export const endpointFieldsFragment = gql`
	fragment EndpointFields on Endpoint {
		ID
		Name
		Status
		UptimePercentage(fromDate: $fromDate)
		AverageResponseTime(fromDate: $fromDate)
		Responses(fromDate: $fromDate) {
			ID
			OK
			ResponseTime
			Error {
				Type
				Error
				StatusCode
			}
			CreatedAt
		}
	}
`;

export const endpointResponseFieldsFragment = gql`
	fragment EndpointResponseFields on EndpointResponse {
		ID
		OK
		ResponseTime
		Error {
			Type
			Error
			StatusCode
			Response
		}
		CreatedAt
		Endpoint {
			URL
		}
	}
`;

export const endpointResponseSubscriptionFieldsFragment = gql`
	fragment EndpointResponseSubscriptionFields on EndpointResponse {
		ID
		OK
		ResponseTime
		Error {
			Type
			Error
			StatusCode
		}
		CreatedAt
		Endpoint {
			ID
			Status
			UptimePercentage(fromDate: $fromDate)
			AverageResponseTime(fromDate: $fromDate)
		}
	}
`;

export const endpointResponseSubscriptionUpdateFragment = gql`
	fragment EndpointStuff on Endpoint {
		Responses(fromDate: $fromDate) {
			ID
			OK
			ResponseTime
			ErrorType
			Error
			CreatedAt
			StatusCode
		}
	}
`;
