import React, {useContext} from 'react';
import {Route, Redirect, useLocation} from 'react-router-dom';

import {AuthContext} from '../../context/auth-context';

const ProtectedRoute = (props) => {
	const {isAuthenticated, initialAuthChecked} = useContext(AuthContext);
	const location = useLocation();

	if (initialAuthChecked && !isAuthenticated) {
		return <Redirect to={{pathname: '/login', state: {from: location}}} />;
	}

	return <Route {...props} />;
};

export default ProtectedRoute;
