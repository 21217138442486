import React, {useEffect, useContext, useState} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {Header} from 'commonsui';
import {Container} from 'react-bootstrap';

import ProtectedRoute from './components/auth/ProtectedRoute';
import Login from './pages/login/Login';
import Logout from './pages/logout/Logout';
import Home from './pages/home/Home';
import Response from './pages/response/Response';
import {AuthContext} from './context/auth-context';
import {useHistory} from 'react-router-dom';
import styles from './App.module.css';

export default function App(props) {
	const history = useHistory();
	const [loggedIn, setLoggedIn] = useState(false);
	const [isInternal, setIsInternal] = useState(false);
	const {isAuthenticated, initialAuthChecked, userData} = useContext(AuthContext);

	useEffect(() => {
		if (initialAuthChecked) {
			if (isAuthenticated) {
				setLoggedIn(true);
				setIsInternal(userData.IsInternal);
			} else {
				setLoggedIn(false);
				setIsInternal(false);
			}
		} else {
			setLoggedIn(false);
			setIsInternal(false);
		}
	}, [isAuthenticated, initialAuthChecked, userData]);

	return (
		<div id="app">
			<Header
				showInternal={isInternal}
				isLoggedIn={loggedIn}
				initialAuthChecked={initialAuthChecked}
				hasFV3Access={false}
				loginButtonClicked={() => {
					history.push('/login');
				}}
			/>
			<Container className={styles.mainContainer}>
				<Switch>
					<Route path="/login" exact component={Login} />
					<Route path="/logout" exact component={Logout} />
					<Route path="/" exact component={Home} />
					<ProtectedRoute path="/response/:id" component={Response} />
					<Redirect to="/" />
				</Switch>
			</Container>
		</div>
	);
}
