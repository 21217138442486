import React, {useState, useEffect} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {Badge, Tabs, Tab} from 'react-bootstrap';

import useIsDev from '../../hooks/useIsDev';

import {SERVICE} from '../../queries/status';
import EndpointOverview from '../endpoint/EndpointOverview';

export default function ServiceOverview(props) {
	const {id, fromDate} = props;
	const {data: serviceData} = useQuery(SERVICE, {
		variables: {id},
	});
	const [tabKey, setTabKey] = useState(null);
	useEffect(() => {
		if (!serviceData) return;
		setTabKey(serviceData.service.Endpoints[0].ID);
	}, [serviceData]);
	const isDev = useIsDev();

	const renderEndpointName = (endpoint) => {
		let badgeText, badgeVariant;
		if (endpoint.Status === 'SLOW') {
			badgeText = 'SLOW';
			badgeVariant = 'warning';
		} else if (endpoint.Status === 'API_ISSUES' && isDev) {
			badgeText = 'ISSUES';
			badgeVariant = 'warning';
		} else if (endpoint.Status === 'ERROR') {
			badgeText = 'ERROR';
			badgeVariant = 'danger';
		} else {
			badgeText = 'OK';
			badgeVariant = 'success';
		}
		return (
			<span>
				{endpoint.Name}
				<Badge className="ml-1" variant={badgeVariant}>
					{badgeText}
				</Badge>
			</span>
		);
	};

	if (serviceData) {
		return (
			<Tabs activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
				{serviceData.service.Endpoints.map((endpoint) => (
					<Tab key={endpoint.ID} eventKey={endpoint.ID} title={renderEndpointName(endpoint)}>
						<EndpointOverview activeEndpointID={tabKey} id={endpoint.ID} fromDate={fromDate} />
					</Tab>
				))}
			</Tabs>
		);
	} else {
		return null;
	}
}
