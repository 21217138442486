import React, {useContext, useEffect} from 'react';
import {Login} from 'commonsui';
import {AuthContext} from '../../context/auth-context';

const LoginContainer = (props) => {
	const {
		isAuthenticated,
		login,
		loginState: {error},
	} = useContext(AuthContext);

	useEffect(() => {
		if (isAuthenticated) {
			var {from} = props.location.state || {from: {pathname: '/'}};
			props.history.replace(from);
		}
	}, [isAuthenticated, props.history, props.location.state]);

	const handleLogin = (values, actions) => {
		login(values.Username, values.Password, values.RememberMe, actions);
	};

	const handleForgotPassword = () => {
		window.location.href = `${process.env.REACT_APP_FRONTIERVIEW_URL}/#forgotPassword`;
	};

	return (
		<Login
			authError={error ? error.message || error : null}
			onLogin={handleLogin}
			onForgotPassword={handleForgotPassword}
		/>
	);
};

export default LoginContainer;
