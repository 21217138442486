import React, {useContext, useEffect, useCallback} from 'react';
import {AuthContext} from '../../context/auth-context';

const Logout = (props) => {
	const {isAuthenticated, logout} = useContext(AuthContext);
	const doTheLogout = useCallback(() => {
		if (isAuthenticated) {
			logout();
			props.history.replace('/login');
		}
	}, [isAuthenticated, logout, props.history]);
	useEffect(() => {
		doTheLogout();
	}, [isAuthenticated, doTheLogout]);
	return <div></div>;
};

export default Logout;
