import gql from 'graphql-tag';
import {endpointResponseSubscriptionFieldsFragment} from '../fragments/status';

export const ENDPOINT_RESPONSE = gql`
	subscription Response($fromDate: String!) {
		response {
			...EndpointResponseSubscriptionFields
		}
	}
	${endpointResponseSubscriptionFieldsFragment}
`;
