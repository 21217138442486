import {ApolloClient} from 'apollo-client';
import {HttpLink} from 'apollo-link-http';
import {WebSocketLink} from 'apollo-link-ws';
import {InMemoryCache, defaultDataIdFromObject} from 'apollo-cache-inmemory';
import {getMainDefinition} from 'apollo-utilities';

import {env} from './util/environment';
import {split} from 'apollo-link';

const cache = new InMemoryCache({
	dataIdFromObject(object) {
		switch (object.__typename) {
			case 'Service':
				return `service:${object.ID}`;
			case 'Endpoint':
				return `endpoint:${object.ID}`;
			case 'EndpointResponse':
				return `endpointresponse:${object.ID}`;
			default:
				return defaultDataIdFromObject(object);
		}
	},
	cacheRedirects: {
		Query: {
			service(_, args, {getCacheKey}) {
				return getCacheKey({__typename: 'Service', ID: args.id});
			},
			endpoint(_, args, {getCacheKey}) {
				return getCacheKey({__typename: 'Endpoint', ID: args.id});
			},
			response(_, args, {getCacheKey}) {
				return getCacheKey({__typename: 'EndpointResponse', ID: args.id});
			},
		},
	},
});
const httpLink = new HttpLink({
	uri: `${env.REACT_APP_API_DOMAIN}/graphql`,
	credentials: 'include',
});
const wsLink = new WebSocketLink({
	uri: env.REACT_APP_WS_ENDPOINT,
	options: {
		reconnect: true,
	},
});

const link = split(
	({query}) => {
		const definition = getMainDefinition(query);
		return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
	},
	wsLink,
	httpLink
);

const client = new ApolloClient({
	fetchOptions: {
		credentials: 'include',
	},
	cache,
	link,
});

export default client;
