import React, {useState, useEffect, useReducer} from 'react';

import {useMutation, useQuery, useLazyQuery} from '@apollo/react-hooks';
import {withApollo} from 'react-apollo';
import mutations from '../mutations/auth';
import queries from '../queries/auth';

import {AlphaTesterRole} from '../util/entitlements';

export const AuthContext = React.createContext({
	user: {},
	entitlements: [],
	isAuthenticated: false,
	login: () => {},
	logout: () => {},
	checkSession: () => {},
});

const SESSION_CHECK_INTERVAL = 60000;
const INITIAL_AUTH_STATE = {
	isAuthenticated: false,
	userData: null,
	userLoggedOut: false,
};

const authReducer = (authState, action) => {
	switch (action.type) {
		case 'LOGIN_FAIL':
			return {...authState, isAuthenticated: false, userData: null};
		case 'LOGIN_SUCCESS':
			return {
				isAuthenticated: true,
				userData: action.userData,
				userLoggedOut: false,
			};
		case 'USER_LOGOUT':
			return {
				isAuthenticated: false,
				userData: null,
				userLoggedOut: true,
			};
		case 'INITIAL_CHECK_SUCCESS':
			return {
				isAuthenticated: true,
				userData: action.userData,
				userLoggedOut: false,
			};
		case 'INITIAL_CHECK_FAIL':
			return {
				isAuthenticated: false,
				userData: null,
				userLoggedOut: false,
			};
		case 'SESSION_CHECK_FAIL':
			// Potentially allow a few failed session checks before logging out
			return {
				isAuthenticated: false,
				userData: null,
				userLoggedOut: false,
			};
		case 'SESSION_CHECK_SUCCESS':
			return {...authState, isAuthenticated: true};
		default:
			return authState;
	}
};

const AuthContextProvider = (props) => {
	const [authState, dispatchAuth] = useReducer(authReducer, INITIAL_AUTH_STATE);
	const [initialAuthCheck, setinitialAuthCheck] = useState(false);
	const [accessError, setAccessError] = useState(null);
	const [formActions, setFormActions] = useState({});
	const [login, {error: loginStateError, data: loginStateData, loading: loginStateLoading}] = useMutation(
		mutations.LOGIN
	);
	const [logout, {data: logoutStateData, loading: logoutStateLoading, error: logoutStateError}] = useMutation(
		mutations.LOGOUT
	);
	const {data: authCheckData, loading: authCheckLoading} = useQuery(queries.INITIAL_AUTH_CHECK);
	const [
		runSessionCheck,
		{data: sessionCheckData, loading: sessionCheckLoading, stopPolling: stopSessionCheck, error: sessionCheckError},
	] = useLazyQuery(queries.SESSION_CHECK, {
		pollInterval: SESSION_CHECK_INTERVAL,
	});

	useEffect(() => {
		if (!initialAuthCheck) {
			if (!authCheckLoading) {
				if (authCheckData && authCheckData.getCurrentUser) {
					const hasAlphaAccess =
						authCheckData.getCurrentUser.Entitlements.Roles.filter(
							(role) => AlphaTesterRole.required.includes(role) || AlphaTesterRole.override.includes(role)
						).length > 0;
					if (hasAlphaAccess) {
						dispatchAuth({
							type: 'INITIAL_CHECK_SUCCESS',
							userData: authCheckData.getCurrentUser,
						});
						runSessionCheck();
					} else {
						setAccessError('You do not have access');
						dispatchAuth({type: 'INITIAL_CHECK_FAIL'});
					}
				} else {
					dispatchAuth({type: 'INITIAL_CHECK_FAIL'});
				}
				setinitialAuthCheck(true);
			}
		}
	}, [authCheckLoading, authCheckData, initialAuthCheck, runSessionCheck]);

	useEffect(() => {
		if (!sessionCheckLoading) {
			if (sessionCheckData && sessionCheckData.checkSession) {
				if (!sessionCheckData.checkSession.isAuthenticated) {
					dispatchAuth({type: 'SESSION_CHECK_FAIL'});
				}
			}
		}
	}, [sessionCheckLoading, sessionCheckData]);

	useEffect(() => {
		if (sessionCheckError) {
			dispatchAuth({type: 'SESSION_CHECK_FAIL'});
		}
	}, [sessionCheckError]);

	useEffect(() => {
		if (loginStateData && loginStateData.hasOwnProperty('login') && loginStateData.login.success) {
			const hasAlphaAccess =
				loginStateData.login.userData.Entitlements.Roles.filter(
					(role) => AlphaTesterRole.required.includes(role) || AlphaTesterRole.override.includes(role)
				).length > 0;
			if (hasAlphaAccess) {
				props.client.cache.reset();
				dispatchAuth({
					type: 'LOGIN_SUCCESS',
					userData: loginStateData.login.userData,
				});
				runSessionCheck();
			} else {
				if (!hasAlphaAccess) {
					setAccessError('You do not have access');
				}
				dispatchAuth({type: 'LOGIN_FAIL'});
			}
		} else if (loginStateData && loginStateData.hasOwnProperty('login') && !loginStateData.login.success) {
			setAccessError('Incorrect username and/or password');
			dispatchAuth({type: 'LOGIN_FAIL'});
		}
	}, [loginStateData, props.client.cache, runSessionCheck]);

	const loginHandler = (email, password, remember, formActions) => {
		login({
			variables: {email: email, password: password, remember: remember},
		});
		setFormActions(formActions);
	};

	useEffect(() => {
		if (loginStateData && loginStateData.hasOwnProperty('login')) {
			formActions.setSubmitting(false);
		}
	}, [loginStateData, formActions]);

	const logoutHandler = () => {
		props.client.cache.reset();
		dispatchAuth({type: 'USER_LOGOUT'});
		if (stopSessionCheck) {
			stopSessionCheck();
		}
		logout();
	};

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated: authState.isAuthenticated,
				initialAuthChecked: initialAuthCheck,
				login: loginHandler,
				loginState: {
					error: loginStateError || accessError,
					data: loginStateData,
					loading: loginStateLoading,
				},
				logout: logoutHandler,
				loggedOut: authState.loggedOut,
				logoutState: {
					error: logoutStateError,
					loading: logoutStateLoading,
					data: logoutStateData,
				},
				initialAuthLoading: sessionCheckLoading,
				userData: authState.userData,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export default withApollo(AuthContextProvider);
