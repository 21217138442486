import React, {Fragment, useMemo, useState} from 'react';
import {Row, Col, Badge, Spinner, Card} from 'react-bootstrap';
import {useQuery, useSubscription} from '@apollo/react-hooks';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';

import useIsDev from '../../hooks/useIsDev';

import {SERVICES} from '../../queries/status';
import {ENDPOINT_RESPONSE} from '../../subscriptions/endpointResponse';
import {endpointResponseSubscriptionUpdateFragment} from '../../fragments/status';

import ServiceOverview from '../../components/service/ServiceOverview';

export default function Home(props) {
	const fromDate = useMemo(() => moment().subtract(7, 'days').format('YYYY-MM-DD'), []);
	const {data: servicesResponse, loading: servicesLoading, error: servicesError} = useQuery(SERVICES);
	useSubscription(ENDPOINT_RESPONSE, {
		variables: {fromDate},
		onSubscriptionData({client, subscriptionData}) {
			const {
				data: {response},
			} = subscriptionData;
			try {
				const content = client.readFragment({
					id: `endpoint:${response.Endpoint.ID}`,
					fragment: endpointResponseSubscriptionUpdateFragment,
					variables: {fromDate},
				});
				const responses = content.Responses;
				responses.push(response);
				client.writeFragment({
					id: `endpoint:${response.Endpoint.ID}`,
					fragment: endpointResponseSubscriptionUpdateFragment,
					data: {
						__typename: content.__typename,
						Responses: responses,
					},
					variables: {fromDate},
				});
			} catch (e) {
				// have no fear! ignore any error from a non loaded endpoint not existing in cache.
			}
		},
	});
	const [expandedMap, setExpandedMap] = useState({});
	const isDev = useIsDev();
	const topLevelStatuses = useMemo(() => {
		const statuses = [];
		if (servicesResponse) {
			for (let service of servicesResponse.services) {
				let status = {
					ID: service.ID,
					Name: service.Name,
					Status: 'OK',
					BadgeVariant: 'success',
				};
				for (let endpoint of service.Endpoints) {
					if (endpoint.Status === 'ERROR') {
						status.Status = 'ERROR';
						status.BadgeVariant = 'danger';
						break;
					}
					if (endpoint.Status === 'API_ISSUES' && isDev) {
						status.Status = 'ISSUES';
						status.BadgeVariant = 'warning';
						break;
					}
					if (endpoint.Status === 'SLOW') {
						status.Status = 'SLOW';
						status.BadgeVariant = 'warning';
						break;
					}
				}
				statuses.push(status);
			}
		}
		return statuses;
	}, [servicesResponse, isDev]);

	if (servicesLoading) {
		return (
			<Row>
				<Col md={12}>
					<Spinner animation="border" />
				</Col>
			</Row>
		);
	} else if (servicesError) {
		return <p>An error occurred.</p>;
	} else {
		return (
			<Fragment>
				<Row>
					{servicesResponse.services.map((service, i) => {
						const status = topLevelStatuses[i];
						return (
							<Col md={12} key={`service-card-${service.ID}`} className="mb-4">
								<Card>
									<Card.Body>
										<Card.Title as="h3">
											{status.Name}
											<Badge variant={status.BadgeVariant} className="float-right">
												{status.Status}
											</Badge>
										</Card.Title>
										<Card.Text>
											{status.Status === 'OK' ? (
												<span>{status.Name} is functioning normally.</span>
											) : status.Status === 'ERROR' ? (
												<span>{status.Name} is currently experiencing elevated error rates.</span>
											) : status.Status === 'ISSUES' ? (
												<span> {status.Name} is currently experiencing elevated error rates.</span>
											) : status.Status === 'SLOW' ? (
												<span> {status.Name} is experiencing slower response times than normal.</span>
											) : null}
										</Card.Text>
										{expandedMap[service.ID] ? (
											<Fragment>
												<ServiceOverview id={service.ID} topLevelStatus={topLevelStatuses[i]} fromDate={fromDate} />
											</Fragment>
										) : null}
										{expandedMap[service.ID] ? (
											<div
												className="text-center"
												style={{cursor: 'pointer'}}
												onClick={() =>
													setExpandedMap((em) => {
														return {...em, [service.ID]: false};
													})
												}
											>
												<h3 className="mb-0">
													<FontAwesomeIcon className="text-secondary" size="lg" icon={faAngleUp} />
												</h3>
											</div>
										) : (
											<div
												className="text-center cursor-pointer"
												style={{cursor: 'pointer'}}
												onClick={() =>
													setExpandedMap((em) => {
														return {...em, [service.ID]: true};
													})
												}
											>
												<h3 className="mb-0">
													<FontAwesomeIcon className="text-secondary" size="lg" icon={faAngleDown} />
												</h3>
											</div>
										)}
									</Card.Body>
								</Card>
							</Col>
						);
					})}
				</Row>
			</Fragment>
		);
	}
}
