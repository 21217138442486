import gql from 'graphql-tag';

export const INITIAL_AUTH_CHECK = gql`
	query {
		getCurrentUser {
			ID
			AvatarURL
			Entitlements {
				Roles
			}
			Email
			Name
			IsInternal
		}
	}
`;

export const SESSION_CHECK = gql`
	query {
		checkSession {
			isAuthenticated
		}
	}
`;

const moduleExports = {INITIAL_AUTH_CHECK, SESSION_CHECK};
export default moduleExports;
