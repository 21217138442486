import gql from 'graphql-tag';

import {serviceFieldsFragment, endpointFieldsFragment, endpointResponseFieldsFragment} from '../fragments/status';

export const SERVICES = gql`
	query Services {
		services {
			...ServiceFields
		}
	}
	${serviceFieldsFragment}
`;

export const SERVICE = gql`
	query Service($id: String!) {
		service(id: $id) {
			...ServiceFields
		}
	}
	${serviceFieldsFragment}
`;

export const ENDPOINT = gql`
	query Endpoint($id: String!, $fromDate: String!) {
		endpoint(id: $id) {
			...EndpointFields
		}
	}
	${endpointFieldsFragment}
`;

export const POLLING_INTERVAL = gql`
	query {
		pollingInterval
	}
`;

export const RESPONSE = gql`
	query Response($id: String!) {
		response(id: $id) {
			...EndpointResponseFields
		}
	}
	${endpointResponseFieldsFragment}
`;

export const RESPONSES = gql`
	query Responses($endpointID: String!, $isError: Boolean, $fromDate: String!) {
		responses(endpointID: $endpointID, isError: $isError, fromDate: $fromDate) {
			ID
			OK
			ResponseTime
			Error {
				ID
				Error
				Type
				StatusCode
			}
			CreatedAt
		}
	}
`;
