import React, {Fragment, useEffect} from 'react';
import {useLazyQuery} from '@apollo/react-hooks';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {Spinner} from 'react-bootstrap';
import ReactJson from 'react-json-view';

import useIsDev from '../../hooks/useIsDev';

import {RESPONSE} from '../../queries/status';

export default function Response(props) {
	const {id} = props.match.params;
	const [fetch, {data, loading, error}] = useLazyQuery(RESPONSE, {variables: {id}});
	const isDev = useIsDev();

	useEffect(() => {
		if (isDev) {
			fetch();
		}
	}, [isDev, fetch]);

	if (!isDev) {
		return <p>You do not have access to this page.</p>;
	} else if (loading) {
		return <Spinner animation="border" />;
	} else if (error) {
		return <p>Error loading response.</p>;
	} else if (data) {
		return (
			<Fragment>
				<Link to="/">Back</Link>
				<p>{moment(data.response.CreatedAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
				<p>
					<a href={data.response.Endpoint.URL} target="_blank" rel="noopener noreferrer">
						{data.response.Endpoint.URL}
					</a>
				</p>
				<pre className="jumbotron p-2 text-wrap">
					<code>{data.response.Error.Error}</code>
				</pre>
				{data.response.Error.Response ? (
					<ReactJson displayDataTypes={false} src={JSON.parse(data.response.Error.Response)} />
				) : null}
			</Fragment>
		);
	} else {
		return null;
	}
}
