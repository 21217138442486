import React, {useState, Fragment, useEffect, useMemo} from 'react';
import {useLazyQuery, useSubscription} from '@apollo/react-hooks';
import {Button, Spinner} from 'react-bootstrap';
import moment from 'moment';

import useIsDev from '../../hooks/useIsDev';
import styles from './css/ErrorLog.module.css';
import {Link} from 'react-router-dom';

import {RESPONSES} from '../../queries/status';
import {ENDPOINT_RESPONSE} from '../../subscriptions/endpointResponse';

export default function ErrorLog(props) {
	const {endpointID, fromDate} = props;
	const [fetch, {data, loading, error}] = useLazyQuery(RESPONSES, {
		variables: {endpointID, isError: true, fromDate},
	});
	const isDev = useIsDev();
	const [showErrors, setShowErrors] = useState(false);
	const [errorsFromSubscription, setErrorsFromSubscription] = useState([]);

	useSubscription(ENDPOINT_RESPONSE, {
		variables: {fromDate},
		onSubscriptionData({client, subscriptionData}) {
			const {
				data: {response},
			} = subscriptionData;
			if (response.Endpoint.ID === endpointID && !response.OK) {
				setErrorsFromSubscription((errors) => {
					return [response, ...errors];
				});
			}
		},
	});

	useEffect(() => {
		if (isDev && showErrors) {
			fetch();
		}
	}, [isDev, showErrors, fetch]);

	const errorsDesc = useMemo(() => {
		const reversed = data ? data.responses.reverse().filter((response) => !!response.Error) : [];
		return [...errorsFromSubscription, ...reversed];
	}, [data, errorsFromSubscription]);

	if (!isDev) return null;
	if (!showErrors) {
		return (
			<Button variant="info" size="sm" onClick={() => setShowErrors(true)}>
				Show Error Log
			</Button>
		);
	} else {
		return (
			<Fragment>
				<h5>Recent Error Log</h5>
				{errorsDesc ? (
					<div className={`${styles.errorContainer} mb-3`}>
						<Fragment>
							{errorsDesc.length === 0 ? <p>No recent errors.</p> : null}
							{errorsDesc.map((response, i) => (
								<div key={response.ID}>
									<p>{moment(response.CreatedAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
									<pre className="jumbotron p-2 text-wrap">
										<code>{response.Error.Error}</code>
									</pre>
									<Link to={`/response/${response.ID}`}>Full Response</Link>
									{i < errorsDesc.length - 1 ? <hr /> : null}
								</div>
							))}
						</Fragment>
					</div>
				) : loading ? (
					<div>
						<Spinner animation="border" />
					</div>
				) : error ? (
					<p>Error loading error log</p>
				) : null}
				<Button variant="info" size="sm" onClick={() => setShowErrors(false)}>
					Hide Error Log
				</Button>
			</Fragment>
		);
	}
}
