import {useContext, useEffect, useState} from 'react';
import {get} from 'lodash';

import {AuthContext} from '../context/auth-context';

const devRole = 6;

export default function useIsDev() {
	const {userData} = useContext(AuthContext);
	const [isDev, setIsDev] = useState(false);

	useEffect(() => {
		if (userData) {
			const roles = get(userData, 'Entitlements.Roles');
			setIsDev(roles.includes(devRole));
		}
	}, [userData]);

	return isDev;
}
